export default {
    home: {
        name:"Цветочная",
        slogan:"Среди цветов как среди добрых и счастливых мыслей",
        author:"Л. Стафф"
    },
    menu: {
        about:"О Нас"
    },
    about: {
        family:"Семейная Цветочная",
        year:"с 30-летней традицией",
        motto:"Наш девиз:"
    },
    cards: {
        wiazanki:"Траурные венки и букеты",
        special:"Поздравительные букеты",
        wedding:"Свадебные украшения и букеты",
        flower:"Корзины и цветочные композиции",
        cemetery:"Траурные декорации"
    },
    footer: {
        delivery:"Возможны заказы по телефону и доставка цветов по указанному адресу.",
        address:"Адрес",
        hours:"Часы работы:",
        days:"Понедельник - воскресенье с 8 до 20",
        account:"Номер банковского счета:"
    }
  
    };